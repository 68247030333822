import React from "react"
import { Parallax } from "react-parallax"
import { Link } from "gatsby"
import bgImage from "../../assets/images/consulting.jpg"
import consultingServicesList from "../../constants/consulting-services-list"
import Services from "../../components/Services"
import Seo from "../../components/utilities/Seo"
const Consulting = () => {
  return (
    <section>
      <Seo title="コンサルティング" />
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={400}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>コンサルティング</h1>
            <h2>米国MBAホルダーによる経営アドバイス</h2>
            <p>お客様のビジネス課題についてご相談に乗ります。</p>
            <div>
              <Link to="/contact" className="btn-primary">
                お問合せ
              </Link>
            </div>
          </div>
        </div>
      </Parallax>

      <Services servicesList={consultingServicesList} />
    </section>
  )
}

export default Consulting
