import React from "react"
import { AiOutlineBuild } from "react-icons/ai"
import { VscGraphScatter } from "react-icons/vsc"
import { GiHorizonRoad } from "react-icons/gi"

const consultingServicesList = [
  {
    icon: <AiOutlineBuild />,
    title: "MBA",
    description:
      "米国経営学修士保持者によるコンサルティング。寄せ集めの知識ではなく、体系的に整理され裏付けある手法による経営戦略立案のお手伝いをいたします。",
  },
  {
    icon: <GiHorizonRoad />,
    title: "中長期的経営戦略",
    description:
      "その場しのぎの解決ではなく、5年先・将来を見据えた経営戦略をお手伝いいたします。",
  },
  {
    icon: <VscGraphScatter />,
    title: "統計分析",
    description:
      "統計的手法に基づいた分析を行い、裏付けあるデータに基づいた戦略立案。",
  },
]
export default consultingServicesList
